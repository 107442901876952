import styled, { css, themeGet, device } from "@styled";
import blueWaveBg from "@data/images/bg/blue-wave-bg.webp";

export const TabsSectionWrapper = styled.section`
  padding-top: 180px;
  padding-bottom: 120px;
  position: relative;
  overflow: hidden;
  background: url(${blueWaveBg});
  background-size: cover;
  background-position: left top;
  background-color: transparent;
  ${(props) =>
    props.layout === 1 &&
    css`
      background: url(${blueWaveBg});
      background-size: cover;
      background-position: left top;
      background-color: transparent;
    `}
  ${(props) =>
    props.layout === 2 &&
    css`
      background: linear-gradient(
        320deg,
        ${themeGet("colors.secondaryDark")},
        ${themeGet("colors.secondary")}
      );
    `}
  .section-title {
    h2 {
      color: #fff;
    }
    p {
      color: #fff;
    }
  }
`;
export const TabsSectionInner = styled.div``;
export const TabsWrapper = styled.div`
  background: rgb(255 255 255 / 15%);
  border-radius: 10px;
  border: 1px solid rgb(255 255 255 / 35%);
  padding: 30px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${device.medium} {
    padding: 30px 50px 50px;
  }
  .nav {
    &-tabs {
      background: linear-gradient(
        320deg,
        ${themeGet("colors.secondary")},
        ${themeGet("colors.secondaryDark")}
      );
    }
    &-link {
      &.active,
      &:hover {
        background: #5e00a9;
        border-color: #5e00a9;
        color: #fff;
      }
    }
  }
  .tab-content {
    width: 100%;
    ul {
      li {
        color: #fff;
        &::before {
          background: #fff;
        }
      }
    }
  }
  h4 {
    font-size: 17px;
    font-weight: bold;
    color: #fff;
  }
  p {
    font-size: 17px;
    color: #fff;
  }
`;
export const TabsBottomImgWrap = styled.div`
  margin-bottom: -120px;
  margin-top: 80px;
  text-align: center;
`;
export const ButtonWrap = styled.div`
  text-align: center;
`;
